import React from 'react'
import PropTypes from 'prop-types'
import BaseCard from 'src/components/Cards/BaseCard'
import { Heading } from 'src/components/Base'
import ReadMoreLink from 'src/components/ReadMoreLink'
import { cEventCard, cReadmore, cHeading } from './styles.module.scss'

const EventCard = ({ title, link, excerpt, date }) => (
  <BaseCard
    className={cEventCard}
    headerHasNoPadding
    backgroundColor="#F7FAFC"
    body={
      <>
        <Heading size={4} as="h3" color="dark" className={cHeading}>
          {title}
        </Heading>
        <ReadMoreLink link={link} className={cReadmore} />
      </>
    }
    date={date}
    hasBorder
    isStretched
  />
)

EventCard.propTypes = {
  title: PropTypes.string.isRequred,
  link: PropTypes.string.isRequred,
  excerpt: PropTypes.string.isRequred,
  date: PropTypes.string.isRequred,
}

export default EventCard
