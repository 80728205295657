import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import EventCard from 'src/components/Cards/EventCard'
import { cEventGrid, cEventCol } from './styles.module.scss'
import { v4 as uuid } from 'uuid'
import { Heading, Section } from 'src/components/Base'
import moment from 'moment'
import LanguageContext from 'src/context/language'

const EventGrid = ({ posts, heading, hasBackground }) => {
  const languageContext = useContext(LanguageContext)
  const eventIsCurrent = (date) => {
    let eventDate = moment(date, 'DD.MM.YYYY hh:mm')
    let today = moment()
    let dayDifference = eventDate.diff(today, 'days')
    if (dayDifference > -30) {
      return true
    } else {
      return false
    }
  }
  const events = posts
    .filter((eventSingle) => {
      return (
        eventSingle.language.locale.toLowerCase().slice(0, 2) ===
        `${languageContext.currentLangauge}`
      )
    })
    .sort((a, b) => {
      let dateA = a.atspEvent.eventDate
      let dateB = b.atspEvent.eventDate

      // switch format so Date.parse can read it - needed splitting to work with time
      dateA = Date.parse(
        `${dateA.split(' ')[0].split('.').reverse().join('-')} ${
          dateA.split(' ')[1]
        }`
      )
      dateB = Date.parse(
        `${dateB.split(' ')[0].split('.').reverse().join('-')} ${
          dateB.split(' ')[1]
        }`
      )

      // to sort in ascending order switch dateA with dateB
      return dateA - dateB
    })

  return (
    <Section hasBackground={hasBackground}>
      {heading && (
        <Heading as="h2" size={2} color="dark" isCentered>
          {heading}
        </Heading>
      )}
      <div className={cEventGrid}>
        {posts &&
          events.map(({ title, link, excerpt, atspEvent }) => {
            if (
              eventIsCurrent(atspEvent.eventDate) ||
              atspEvent.eventDate === null
            ) {
              return (
                <div key={uuid()} className={cEventCol}>
                  <EventCard
                    title={title}
                    link={link}
                    excerpt={excerpt}
                    date={atspEvent.eventDate}
                  />
                </div>
              )
            }
          })}
      </div>
    </Section>
  )
}

EventGrid.propTypes = {
  posts: PropTypes.array.isRequred,
  heading: PropTypes.string,
  hasBackground: PropTypes.boolean,
}

EventGrid.defaultProps = {
  heading: undefined,
  hasBackground: false,
}

export default EventGrid
