import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Section, Wysiwyg, Container } from 'src/components/Base'
import EventGrid from 'src/components/EventGrid'
import Seo from 'src/components/Seo'
import { Hero, ContactPerson } from 'src/sections'

const TemplateEventOverview = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspEventOverview.eventOverviewIntro
  const contact = page.atspEventOverview.eventOverviewContactPerson
  const contactData = contact.contactPerson
  const events = page.atspEventOverview.eventOverviewEvents
  const portrait =
    contactData.atspcontacts.portrait.localFile.childImageSharp
      .gatsbyImageData || undefined
  const altText = contactData.atspcontacts.portrait.altText || undefined

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
        </Container>
      </Section>
      <EventGrid
        heading={events.heading}
        posts={data.allWpEvent.nodes}
        hasBackground={false}
      />
      <ContactPerson
        heading={contact.heading}
        name={contactData.title}
        extensionNumber={contactData.atspcontacts.contactData.extension}
        email={contactData.atspcontacts.contactData.email}
        portrait={portrait}
        altText={altText}
      />
    </>
  )
}

export const pageQuery = graphql`
  query EventOverviewById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
      atspEventOverview {
        eventOverviewContactPerson {
          contactPerson {
            ... on WpKontakt {
              id
              title
              atspcontacts {
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                contactData {
                  email
                  extension
                }
              }
            }
          }
          heading
        }
        eventOverviewEvents {
          heading
        }
        eventOverviewIntro {
          heading
          wysiwyg
        }
      }
    }
    allWpEvent(sort: { order: DESC, fields: atspEvent___eventDate }) {
      nodes {
        atspEvent {
          eventDate
        }
        title
        link
        language {
          locale
        }
      }
    }
  }
`
export default TemplateEventOverview
